import { Button } from '@material-ui/core';
import { useConnection, useAnchorWallet } from '@solana/wallet-adapter-react';
import React, { FC, useCallback } from 'react';
import { useNotify } from './notify';

import {
  SystemProgram,
  PublicKey,
  TransactionInstruction,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import { MintLayout, Token } from "@solana/spl-token";
import * as anchor from "@project-serum/anchor";
import './MintToken.css';




const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);
const TOKEN_PROGRAM_ID = new PublicKey(
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
);

const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

const programId = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

const CANDY_MACHINE = "candy_machine";

const getTokenWallet = async function (wallet: PublicKey, mint: PublicKey) {
  return (
    await PublicKey.findProgramAddress(
      [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    )
  )[0];
};

const getCandyMachine = async (config: anchor.web3.PublicKey, uuid: string) => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from(CANDY_MACHINE), config.toBuffer(), Buffer.from(uuid)],
    programId
  );
};

const getMetadata = async (
  mint: anchor.web3.PublicKey
): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

const getMasterEdition = async (
  mint: anchor.web3.PublicKey
): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from("edition"),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

export function createAssociatedTokenAccountInstruction(
  associatedTokenAddress: PublicKey,
  payer: PublicKey,
  walletAddress: PublicKey,
  splTokenMintAddress: PublicKey
) {
  const keys = [
    {
      pubkey: payer,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: associatedTokenAddress,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: walletAddress,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: splTokenMintAddress,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new TransactionInstruction({
    keys,
    programId: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    data: Buffer.from([]),
  });
}







export default function MintToken() {
    const { connection } = useConnection();

    const wallet = useAnchorWallet();

    const notify = useNotify();

    const cachedContent = {
      program: {"uuid":"","config":""},
    };

    const onClick = useCallback(async () => {

        if (!wallet?.publicKey) {
            notify('error', 'Wallet not connected!');
            return;
        }

        const mint = anchor.web3.Keypair.generate();

        const token = await getTokenWallet(wallet.publicKey, mint.publicKey);

        console.log(1);

        const provider = new anchor.Provider(connection, wallet, {
          preflightCommitment: "recent",
        });
        const idl = await anchor.Program.fetchIdl(programId, provider);
        const anchorProgram = new anchor.Program(idl, programId, provider);
        const config = new anchor.web3.PublicKey(cachedContent.program.config);
        const [candyMachine, bump] = await getCandyMachine(
          config,
          cachedContent.program.uuid
        );
        const candy = await anchorProgram.account.candyMachine.fetch(candyMachine);
        const metadata = await getMetadata(mint.publicKey);
        const masterEdition = await getMasterEdition(mint.publicKey);

        console.log(mint.publicKey);

            const tx = await anchorProgram.rpc.mintNft({
              accounts: {
                config: config,
                candyMachine: candyMachine,
                payer: wallet.publicKey,
                //@ts-ignore
                wallet: candy.wallet,
                mint: mint.publicKey,
                metadata,
                masterEdition,
                mintAuthority: wallet.publicKey,
                updateAuthority: wallet.publicKey,
                tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
                tokenProgram: TOKEN_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
                rent: anchor.web3.SYSVAR_RENT_PUBKEY,
                clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
              },
              signers: [mint],
              instructions: [
                anchor.web3.SystemProgram.createAccount({
                  fromPubkey: wallet.publicKey,
                  newAccountPubkey: mint.publicKey,
                  space: MintLayout.span,
                  lamports: await provider.connection.getMinimumBalanceForRentExemption(
                    MintLayout.span
                  ),
                  programId: TOKEN_PROGRAM_ID,
                }),
                Token.createInitMintInstruction(
                  TOKEN_PROGRAM_ID,
                  mint.publicKey,
                  0,
                  wallet.publicKey,
                  wallet.publicKey
                ),
                createAssociatedTokenAccountInstruction(
                  token,
                  wallet.publicKey,
                  wallet.publicKey,
                  mint.publicKey
                ),
                Token.createMintToInstruction(
                  TOKEN_PROGRAM_ID,
                  mint.publicKey,
                  token,
                  wallet.publicKey,
                  [],
                  1
                ),
              ],
            }).then(function(result) {
                notify('success', 'Mint request successful! Check your wallet!');
            }).catch(error => {
                console.log(error);
                let error_json = {
                    "code": null,
                    "msg": ""
                }
                let error_string = JSON.stringify(error);
                error_json = JSON.parse(error_string);
                notify('error', `Minting failed! ${error_json.msg}`);
            });



    }, [wallet?.publicKey, notify, connection, cachedContent]);

    let mintButton;
    if (!cachedContent.program.uuid) {
      mintButton = <div className={!wallet?.publicKey ? 'mintButtonPreviewHidden' : 'mintButtonPreview'} style={{display: "inline-block", borderRadius: "5px", width: "auto", margin: "0px auto 30px auto", padding: "20px", fontSize: "20px", fontWeight: "bold", backgroundColor: "rgb(150, 150, 150)", color: "black"}}>COMING SOON</div>;
    } else {
      mintButton = <Button style={{borderRadius: "5px", width: "auto", margin: "0px auto 30px auto", padding: "10px 20px", fontSize: "20px", fontWeight: "bold", backgroundColor: "rgb(200, 200, 200)", color: "black"}} className="mintButton" variant="contained" color="secondary" onClick={onClick} disabled={!wallet?.publicKey}>Mint one SolNumber</Button>;
    }

    return (
          <div>{mintButton}</div>
    );
};
