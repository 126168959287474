//import logo from './logo.svg';
import logo from './new_logo.png';
import questionGIF from './images/NFT-gif.gif';
import einstein from './images/einstein.jpeg';
import newton from './images/newton.jpeg';
import leibniz from './images/leibniz.jpeg';
import galilei from './images/galilei.jpeg';
import './App.css';
import PageModal from './components/PageModal/PageModal.js';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import FAQ from './components/FAQ/FAQ.js';
import WalletConnector from './components/WalletConnector/WalletConnector.tsx';
import Button from '@material-ui/core/Button';

import Countdown, { zeroPad } from 'react-countdown';


// Random component
const Completionist = () => <span>Countdown will start soon!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <Grid container spacing={3} style={{fontSize: '24px', fontWeight: 'bold'}}> 
             <Grid item xs={3}>{zeroPad(days, 2)}<br/><span style={{fontSize: '14px', fontWeight: 'normal'}}>days</span></Grid> 
             <Grid item xs={3}>{zeroPad(hours, 2)}<br/><span style={{fontSize: '14px', fontWeight: 'normal'}}>hours</span></Grid>
             <Grid item xs={3}>{zeroPad(minutes, 2)}<br/><span style={{fontSize: '14px', fontWeight: 'normal'}}>minutes</span></Grid>
             <Grid item xs={3}> {zeroPad(seconds, 2)}<br/><span style={{fontSize: '14px', fontWeight: 'normal'}}>seconds</span></Grid> 
           </Grid>;
  }
};

function App() {
  return (
    <div className="App">
      <header className="App-header">

        

        <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} >
            <img src={logo} className="App-logo" alt="logo" />
            <h1>
              SolNumbers
            </h1>
            <p style={{lineHeight: "1.8", fontSize: "18px"}}>Sick of animal NFTs? That's why we created SolNumbers! 10,000 NFTs with each number from 1-10000 living on the Solana Blockchain!</p>
            
            <Button variant="contained" color="primary" target="_blank" href="https://discord.gg/Em5GyB58Hj">
              Join our Discord!
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>

            <div className="wallet-selection">
              <p className="title-wallet">10,000 NFTs Remaining</p>
              <p className="title-wallet-sub">Dropping Date coming soon</p>
              {/*<div className="countdown-area">
                <Countdown
                  date={'2021-09-08 21:00:00'}
                  renderer={renderer}
                  zeroPadTime={2}
                />
              </div>*/}
              <img src={questionGIF} className="question-gif"/>
              <p className="title-wallet-bold"> SolNumbers are X SOL each</p>
              <WalletConnector />

            </div>
            
          </Grid>
        </Grid>
        </Container>

      </header>

      <section className="team-section">
        <h1>The Team</h1>
        <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="team-member">
              <img src={einstein}/>
              <h3>Soleinstein</h3>
              <h5>Community</h5>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="team-member">
              <img src={newton}/><h3>Solewton</h3>
              <h5>Developer</h5>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="team-member">
              <img src={leibniz}/><h3>Soleibniz</h3>
              <h5>Artist</h5>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="team-member">
              <img src={galilei}/><h3>Solalilei</h3>
              <h5>Marketing</h5>
            </div>
          </Grid>
        </Grid>
        </Container>
      </section>
      <section className="faq-section">
        <h1 style={{color: "white"}}>FAQ</h1>
        <Container maxWidth="sm">
          <FAQ />
        </Container>
      </section>
      <footer>
        <PageModal />
      </footer>
    </div>
  );
}

export default App;
