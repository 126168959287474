import { WalletError } from '@solana/wallet-adapter-base';
/*
import {
    WalletConnectButton as MaterialUIWalletConnectButton,
    WalletDialogButton as MaterialUIWalletDialogButton,
    WalletDialogProvider as MaterialUIWalletDialogProvider,
    WalletDisconnectButton as MaterialUIWalletDisconnectButton,
    WalletMultiButton as MaterialUIWalletMultiButton,
} from '@solana/wallet-adapter-material-ui';
*/
/*
import {
    WalletConnectButton as MaterialUIWalletConnectButton,
    WalletDialogButton as MaterialUIWalletDialogButton,
    WalletDialogProvider as MaterialUIWalletDialogProvider,
    WalletDisconnectButton as MaterialUIWalletDisconnectButton,
    WalletMultiButton as MaterialUIWalletMultiButton,
} from '@solana/wallet-adapter-react-ui';
*/
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { ConnectionProvider, useLocalStorage, WalletProvider } from '@solana/wallet-adapter-react';
import {
    getBitpieWallet,
    getCoin98Wallet,
    getLedgerWallet,
    getMathWallet,
    getPhantomWallet,
    getSolflareWallet,
    getSolletWallet,
    getSolongWallet,
    getTorusWallet,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useEffect } from 'react';
import './WalletConnector.css';
import MintToken from '../MintToken/MintToken';

export default function WalletConnector() {
    const endpoint = useMemo(() => clusterApiUrl('devnet'), []);
    const [autoConnect, setAutoConnect] = useLocalStorage('autoConnect', true);

    const wallets = useMemo(
        () => [
            getPhantomWallet(),
            getSolflareWallet(),
            getTorusWallet({
                options: {
                    clientId: 'BOM5Cl7PXgE9Ylq1Z1tqzhpydY0RVr8k90QQ85N7AKI5QGSrr9iDC-3rvmy0K_hF0JfpLMiXoDhta68JwcxS1LQ',
                },
            }),
            getLedgerWallet(),
            getSolongWallet(),
            getMathWallet(),
            getSolletWallet(),
            getCoin98Wallet(),
            getBitpieWallet()
        ],
        []
    );

    const { enqueueSnackbar } = useSnackbar();
    const onError = useCallback(
        (error: WalletError) => {
            if (error.name != "WalletDisconnectedError") {
                enqueueSnackbar(error.message ? `${error.name}: ${error.message}` : error.name, { variant: 'error' });
            }
            console.error(error);
        },
        [enqueueSnackbar]
    );

    /*
    let mintButton;
    alert(publicKey);
    if (publicKey) {
      mintButton = <div>
                    <div style={{display: "inline-block", borderRadius: "5px", width: "auto", margin: "0px auto 30px auto", padding: "20px", fontSize: "20px", fontWeight: "bold", backgroundColor: "rgb(150, 150, 150)", color: "black"}}>COMING SOON</div>
                   </div>;
    } else {
      mintButton = <div></div>;
    }
    */

    

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} onError={onError} autoConnect={autoConnect}>

                <WalletModalProvider>
                    <WalletMultiButton style={{margin: "20px auto", backgroundColor: "rgb(50,50,50)"}}/>
                </WalletModalProvider>

                <MintToken />

            </WalletProvider>
        </ConnectionProvider>
    );
};